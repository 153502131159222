import React from 'react'
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal';
import Layout from '../../components/layout'

const CarbonCrediting = props => (
    <Layout>
        <Helmet>
            <title>Carbon Crediting</title>
            <meta name="description" content="Carboncrediting Info" />
        </Helmet>
        <Fade duration={2500}>
            <div id="main" className="wrapper style1">
                <div className="container">
                <header className="major">
                    <h2>Carbon Crediting</h2>
                    <p>
                    Carbon Trading refers to the buying and selling of carbon credits. Carbon credits are credits that are authorized by governments and are what permits a company or country to emit carbon dioxide. The main goal of carbon credits as derived in the Kyoto Protocol is to reduce carbon dioxide emissions and mitigate climate change.
                    </p>
                </header>
                <div className="row gtr-150">
                    <div style={{textAlign:`center`}}><b>Carbon Trading allows entities to sell their unused carbon credits in exchange for a monetary price. This provides an incentive for companies and countries to cut down on their carbon emissions either via scaling down their production or finding innovative methods or technologies that are more environmentally sustainable. </b>
                    </div>
                    <br/>
                    <div style={{textAlign:`center`}}>
                    <b>We generate our own carbon credits through our <a href="/tech/fibre">Allied Fibres Program</a> - which we will put up for sale as well, for companies and governments keen on meeting their emissions targets.</b>
                    </div>
                    <br/>
                </div>
                
                </div>
            </div>
        </Fade>
    </Layout>
)

export default CarbonCrediting